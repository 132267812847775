<template>
    <div>
        <PainelAplicacaoInvetarioRisco>
            <template #header>
                <Button class="p-button p-button-outlined" icon="pi pi-arrow-left" label="Voltar" @click="confirmBack" />
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                  <AppInfoManual nomeTelaDoManual="inventario-risco-form" />
                  <h3 class="ml-1">{{ title }}</h3>
             </div>
            </template>
            <template #content>
                <GridAplicacaoInventarioRisco ref="gridAplicacaoInventarioRisco" />
            </template>
            <template #acoesAplicacao>
                <Button class="mt-3" :loading="loading" icon="pi pi-check" :label="labelIniciarOuAlterar" @click="iniciar" />
            </template>
            <template v-if="form.perfilExposicao && stepItems" #steps>
                <Steps class="mt-5 mb-5" :model="stepItems" :readonly="false">
                    <template #item="{ item }">
                        <router-link :to="item.to" custom v-slot="{ href, navigate, isActive, isExactActive }">
                            <a
                                :href="href"
                                @click="navigate"
                                :class="{ 'active-link': isActive, 'active-link-exact': isExactActive }"
                                :style="`${item.color}`"
                            >
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center mt-7">
                                        <div :class="{ circulo: isActive }">
                                            <i :class="item.icon" style="font-size: 2rem"></i>
                                        </div>
                                    </div>
                                    <div
                                        :class="isActive ? 'font-bold border-bottom-2 text-base' : ''"
                                        class="flex align-items-center justify-content-center h-3rem"
                                    >
                                        {{ item.label }}
                                    </div>
                                    <div :class="isActive ? 'mt-2 flex justify-content-center' : 'mb-8'">
                                        <Button
                                            :loading="loadingIniciarEtapa"
                                            icon="pi pi-play"
                                            v-if="isActive && item.to !== etapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO"
                                            class="p-button-sm"
                                            label="Iniciar"
                                            :disabled="disabledBotaoIniciar"
                                            @click="() => iniciarEtapa(item)"
                                        />
                                    </div>
                                </div>
                            </a>
                        </router-link>
                    </template>
                </Steps>

                <router-view v-slot="{ Component }">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </template>
        </PainelAplicacaoInvetarioRisco>

        <DialogIniciarInventarioRisco
            ref="dialogIniciarInventarioRisco"
            :customerId="form.clienteId"
            :inventarioRiscoId="form.id"
            :perfilExposicaoId="form.perfilExposicaoId"
            @onIniciarAplicacao="onIniciarAplicacao"
            @onAlterarPerfilExposicao="onAlterarPerfilExposicao"
        />
    </div>
</template>

<script>
import PainelAplicacaoInvetarioRisco from './components/PainelAplicacaoInvetarioRisco.vue';
import EtapasInventarioRiscoEnum, { EtapasInventarioRiscoLabelEnum, EtapasInventarioRiscoPathEnum } from '../../enums/EtapasInventarioRiscoEnum';
import GridAplicacaoInventarioRisco from './components/GridAplicacaoInventarioRisco.vue';
import DropdownPerfil from './components/DropDownPerfil.vue';
import DialogIniciarInventarioRisco from './components/DialogIniciarInventarioRisco.vue';
import InventarioRiscoService from './services/InventarioRiscoService';
import { showError, showSuccess } from '../../utils/Toast';
import eventBus from '../../event-bus';
import StatusAplicacaoInventario from '../../enums/StatusAplicacaoInventario';
import EtapasService from './services/EtapasService';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
    PainelAplicacaoInvetarioRisco,
    GridAplicacaoInventarioRisco,
    DropdownPerfil,
    DialogIniciarInventarioRisco,
    AppInfoManual
},
    computed: {
        title() {
            return 'Inventario de risco';
        },
        labelIniciarOuAlterar() {
            return this.form.perfilExposicaoId ? 'Alterar perfil de exposição' : 'Iniciar aplicação';
        }
    },
    watch: {
        'form.perfilExposicao'(value) {
            this.form.perfilExposicaoId = value?.id;
        }
    },
    created() {
        this.service = new InventarioRiscoService();
        this.load();
    },
    mounted() {
        eventBus.on('onReloadSteps', () => this.load());
        eventBus.on('disabledButtonIniciar', (podeVisualizar) => this.disabledBotaoIniciar = podeVisualizar);
    },
    data() {
        return {
            form: {},
            service: null,
            loading: false,
            loadingIniciarEtapa: false,
            stepItems: null,
            disabledBotaoIniciar: false,
            etapasInventarioRiscoEnum: EtapasInventarioRiscoEnum,
            etapasInventarioRiscoPathEnum: EtapasInventarioRiscoPathEnum
        };
    },
    methods: {
        setStatusColor() {
            if (this.form?.aplicacaoLevantamentos[0]) {
                const el = this.stepItems.find(
                    (el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO)
                );
                el.color = this.getStatusColor(this.form.aplicacaoLevantamentos[0].status);
            }

            if (this.form?.aplicacaoAep[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.AEP_AET));
                el.color = this.getStatusColor(this.form.aplicacaoAep[0].status);
            }

            if (this.form?.aplicacaoAet[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.AEP_AET));
                el.color = this.getStatusColor(this.form?.aplicacaoAet[0].status);
            }

            if (this.form?.aplicacaoPgr[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.PGR));
                el.color = this.getStatusColor(this.form?.aplicacaoPgr[0].status);
            }

            if (this.form?.aplicacaoLaudo[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.LTCAT_LTIP));
                el.color = this.getStatusColor(this.form?.aplicacaoLaudo[0].status);
            }

            if (this.form?.aplicacaoPcmso[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.PCMSO));
                el.color = this.getStatusColor(this.form?.aplicacaoPcmso[0].status);
            }

            if (this.form?.aplicacaoConclusao[0]) {
                const el = this.stepItems.find((el) => el.label == EtapasInventarioRiscoLabelEnum.get(EtapasInventarioRiscoEnum.CONCLUIR));
                el.color = this.getStatusColor(this.form?.aplicacaoConclusao[0].status);
            }
        },
        getStatusColor(status) {
            const Status = {
                NAO_CONTRATADO: 'color: var(--gray-200)',
                NAO_INICIADO: 'color: var(--gray-500)',
                EM_ANDAMENTO: 'color: var(--yellow-500)',
                CONCLUIDO: 'color: var(--green-500)'
            };

            return Status[status];
        },
        async load() {
            try {
                const { data } = await this.service.findByAplicacaoId(this.$route.params.id);
                this.form = data;
                if (this.form.perfilExposicao) {
                    await this.carregarSteps();
                    this.setStatusColor();
                }
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async carregarSteps() {
            try {
                const { data } = await this.service.findStepsByInventarioRisco(this.$route.params.id);
                this.stepItems = data;
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async iniciar() {
            this.$refs.dialogIniciarInventarioRisco.open();
        },
        async onIniciarAplicacao(inventarioRiscoId, perfilExposicaoId, transferirHierarquia = false) {
            try {
                this.alterarPerfilExposicao(perfilExposicaoId);
                if (transferirHierarquia) {
                    this.alterarInventarioRiscoId(inventarioRiscoId);
                    await this.onAlterarPerfilExposicao(inventarioRiscoId, this.form.perfilExposicaoId, transferirHierarquia);
                    return;
                }

                this.loading = true;
                await this.service.iniciar(this.$route.params.id, this.form);
                this.loading = false;
                showSuccess(this.$toast, 'Registro salvo com sucesso!');
                await this.$router.push({
                    name: this.form.etapa,
                    params: { id: this.form.id }
                });
                await this.load();
                await this.$refs.gridAplicacaoInventarioRisco.load(this.$route.params.id);
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        async onAlterarPerfilExposicao(inventarioRiscoId, perfilExposicaoId, transferirHierarquia = false) {
            try {
                this.loading = true;

                if (transferirHierarquia) {
                    this.alterarInventarioRiscoId(inventarioRiscoId);
                }

                this.alterarPerfilExposicao(perfilExposicaoId);
                const { data } = await this.service.alterarPerfilExposicao(this.$route.params.id, {
                    inventarioRiscoId: this.form.id,
                    perfilExposicaoId: this.form.perfilExposicaoId
                });

                this.loading = false;
                showSuccess(this.$toast, 'Registro salvo com sucesso!');
                await this.$router.push({
                    name: data.etapa,
                    params: { id: data.id }
                });
                await this.load();
                await this.$refs.gridAplicacaoInventarioRisco.load();
                eventBus.emit('onAfterTransfer');
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        alterarPerfilExposicao(perfilExposicaoId) {
            this.form.perfilExposicaoId = perfilExposicaoId;
        },
        alterarInventarioRiscoId(inventarioRiscoId) {
            this.form.id = inventarioRiscoId;
        },
        confirmBack() {
            this.$router.push('/security/inventario-risco2');
        },
        async iniciarEtapa({ to }) {
            let pathController = '';
            switch (to) {
                case EtapasInventarioRiscoEnum.AEP_AET:
                    pathController = this.etapasInventarioRiscoPathEnum.get(EtapasInventarioRiscoEnum.AEP_AET);
                    await this.processarInicializacao(this.form?.aplicacaoAep[0], pathController);
                    break;
                case EtapasInventarioRiscoEnum.PGR:
                    pathController = this.etapasInventarioRiscoPathEnum.get(EtapasInventarioRiscoEnum.PGR);
                    await this.processarInicializacao(this.form?.aplicacaoPgr[0], pathController);
                    break;
                case EtapasInventarioRiscoEnum.LTCAT_LTIP:
                    pathController = this.etapasInventarioRiscoPathEnum.get(EtapasInventarioRiscoEnum.LTCAT_LTIP);
                    await this.processarInicializacao(this.form?.aplicacaoLaudo[0], pathController, 'onLoadLaudoScreen', this.form?.aplicacaoLaudo[0]?.id)
                    break;
                case EtapasInventarioRiscoEnum.PCMSO:
                    pathController = this.etapasInventarioRiscoPathEnum.get(EtapasInventarioRiscoEnum.PCMSO);
                    await this.processarInicializacao(this.form?.aplicacaoPcmso[0], pathController);
                    break;
                case EtapasInventarioRiscoEnum.CONCLUIR:
                    pathController = this.etapasInventarioRiscoPathEnum.get(EtapasInventarioRiscoEnum.CONCLUIR);
                    await this.processarInicializacao(this.form?.aplicacaoConclusao[0], pathController);
                    break;
                default:
                    break;
            }
            await this.load();
        },

        async processarInicializacao(dto, pathController, emitName, etapaId) {
            try {
                this.loadingIniciarEtapa = true;
                const service = new EtapasService();
                const dtoInicializacao = {
                    stepAplicacaoId: dto.id,
                    status: StatusAplicacaoInventario.EM_ANDAMENTO,
                    inventarioRiscoId: dto.inventarioRiscoId
                };
                await service.iniciar(dtoInicializacao, pathController);
                this.loadingIniciarEtapa = false;
                this.disabledBotaoIniciar = true;
                this.emitEtapas(emitName, etapaId)
                showSuccess(this.$toast, 'Etapa iniciada com sucesso.');
            } catch (error) {
                this.loadingIniciarEtapa = false;
                this.disabledBotaoIniciar = false;
                showError(this.$toast, error);
            }

        },

        emitEtapas(emitName, etapaId) {
            eventBus.emit(emitName, etapaId);
        }
    }
};
</script>

<style>
.circulo {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: var(--color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
</style>
