<template>
    <div>
        <Dialog
            :visible="isOpen"
            class="p-fluid"
            :style="{ width: '50%', height: 'auto' }"
            :header="title"
            :draggable="false"
            :closable="false"
            :modal="true"
        >
            <div class="flex flex-column">
                <label for="cliente">Cliente</label>
                <DropdownCliente 
                    id="cliente"
                    :idValue="form.customerId"
                    v-model="form.cliente"
                    :disabled="true"
                    :showClear="false"
                />
                <Divider align="center">
                    <span class="p-tag">Perfil de exposição</span>
                </Divider>       
            </div>
            <div class="flex flex-row align-items-center gap-2">
                <div class="flex flex-column flex-grow-1">
                    <label  for="perfilExposicao">Perfil de exposição</label>
                    <DropdownPerfil
                        ref="dropdownPerfilExposicao"
                        v-show="!isNovoPerfil"
                        id="perfilExposicao"
                        :idValue="perfilExposicaoId" 
                        v-model="form.perfilExposicao" 
                        :customerId="customerId"
                    />
                    <InputText 
                        v-show="isNovoPerfil"
                        id="nomePerfil"
                        v-model.trim="form.nome"
                        placeholder="Digite o nome do perfil"
                    />
                </div>
                <div class="flex flex-column flex-grow-none">
                    <Button
                        v-if="!isNovoPerfil"
                        icon="pi pi-plus"
                        v-tooltip.bottom="'Novo perfil'"
                        class="mt-4 p-button-rounded p-button-primary p-button"
                        @click="onClickNovoPerfil"
                    />
                    <div v-else class="flex flex-row flex-grow-none gap-2">
                        <Button
                            :disabled="loadingSalvarPerfil"
                            icon="pi pi-times"
                            v-tooltip.bottom="'Cancelar'"
                            class="mt-4 p-button-outlined p-button-rounded p-button-danger"
                            @click="onClickCancelarCadastro"
                        />
                        <Button
                            :loading="loadingSalvarPerfil"
                            icon="pi pi-check"
                            v-tooltip.bottom="'Salvar'"
                            class="mt-4 p-button-outlined p-button-rounded p-button-success"
                            @click="onClickConfirmNovoPerfil"
                        />
                    </div>
                </div>
                
            </div>
            <template #footer>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    class="p-button-text"
                    @click="onClickClose"
                />
                <Button
                    :loading="loadingIniciarAplicacao"
                    :disabled="isDisableButton"
                    :label="title"
                    icon="pi pi-check"
                    class="p-button"
                    @click="onConfirm"
                />
            </template>
        </Dialog>

        <ConfirmDialog 
            :closable="false" 
            :draggable="false" 
            group="templating"
        >
            <template #message="slotProps">
                <div class="flex flex-column justify-content-center align-items-center">
                    <div class="flex-1 pb-4">
                        <i :class="slotProps.message.icon" class="text-7xl text-400"></i>
                    </div>
                    <div class="flex">
                        <p class="font-medium">{{slotProps.message.message}}</p>
                    </div>
                     <div class="flex pb-3">
                        <p class="font-medium">Deseja incluir as hierarquias no perfil de exposição {{ this.form?.perfilExposicao?.nome }}?</p>
                    </div>
                    <div class="flex">
                        <p class="font-bold">Esta ação não poderá ser desfeita!</p>
                    </div>
                </div>
            </template>
        </ConfirmDialog>
    </div>
</template>

<script>
import BaseService from '@/services/BaseService';
import PerfilExposicaoService from '../services/PerfilExposicaoService';
import { showError, showSuccess } from '../../../utils/Toast';
import DropdownCliente from '../../clientes/components/DropdownCliente.vue';
import DropdownPerfil from '../components/DropDownPerfil.vue';

export default {
    emits: ['onIniciarAplicacao', 'onAlterarPerfilExposicao'],
    components: {
        DropdownCliente,
        DropdownPerfil
    },
    props: {
        customerId: {
            type: Number
        },
        inventarioRiscoId: {
            type: Number
        },
        perfilExposicaoId: {
            type: Number
        }
    },
    watch: {
        customerId(value) {
            this.form.customerId = value;
        }
    },
    computed: {
        title() {
            return this.perfilExposicaoId ? 'Alterar perfil' : 'Iniciar';
        },
        isDisableButton() {
            return this.isNovoPerfil || !this.form?.perfilExposicao?.id;
        }
    },
    data() {
        return {
            isOpen: false,
            isNovoPerfil: false,
            form: {},
            loadingIniciarAplicacao: false,
            loadingSalvarPerfil: false,
            service: null,
            servicePerfilExposicao: null
        }
    },
    created() {
        this.servicePerfilExposicao = new PerfilExposicaoService();
    },
    methods: {
        onClickClose() {
            this.resetarCampos();
            this.isOpen = false;
        },
        onClickNovoPerfil() {
            this.isNovoPerfil = true;
        },
        onClickCancelarCadastro() {
            this.resetarCampos();
        },
        async onClickConfirmNovoPerfil() {
            try {
                this.loadingSalvarPerfil = true;
                const service = new BaseService('/perfil-exposicao');
                const { data } = await service.save(this.form);
                this.form.perfilExposicao = data;
                showSuccess(this.$toast, 'Perfil de exposição criado com sucesso!');
                this.$refs.dropdownPerfilExposicao.load();
                this.loadingSalvarPerfil = false;
                this.resetarCampos();
            } catch (error) {
                this.loadingSalvarPerfil = false;
                showError(this.$toast, error);
            }
            
        },
        resetarCampos() {
            this.isNovoPerfil = false;
            this.form.nome = null;
        },
        async onConfirm() {
            this.loadingIniciarAplicacao = true;
            if (this.perfilExposicaoId) {
                await this.alterarPerfilExposicao();
                this.loadingIniciarAplicacao = false;
                return;
            }
        
            await this.iniciarAplicacao();
            this.loadingIniciarAplicacao = false;
        },
        async iniciarAplicacao() {
            const vinculoPerfilExposicaoComInventarioRisco = await this.verificarVinculoPerfilExposicaoComInventarioRisco();

            if (vinculoPerfilExposicaoComInventarioRisco.perfilExposicaoId) {
                this.openConfirmDialogTransferenciaHierarquia(vinculoPerfilExposicaoComInventarioRisco.inventarioRiscoId);
                return;
            }

            await this.$emit('onIniciarAplicacao', 0, this.form.perfilExposicao.id);
            this.onClickClose();
        },
        async alterarPerfilExposicao() {
            const vinculoPerfilExposicaoComInventarioRisco = await this.verificarVinculoPerfilExposicaoComInventarioRisco();

            if (vinculoPerfilExposicaoComInventarioRisco.perfilExposicaoId) {
                this.openConfirmDialogTransferenciaHierarquia(vinculoPerfilExposicaoComInventarioRisco.inventarioRiscoId, true);
                return;
            }
            
            await this.$emit('onAlterarPerfilExposicao', this.inventarioRiscoId, this.form.perfilExposicao.id);
            this.onClickClose();
        },
        async verificarVinculoPerfilExposicaoComInventarioRisco() {
            const { data } = await this.servicePerfilExposicao.verificarVinculacaoComInventarioRisco(this.form.perfilExposicao.id);
            return data;
        },
        open() {
            this.isOpen = true;
        },
        openConfirmDialogTransferenciaHierarquia(inventarioRiscoId, alterar = false) {
            this.$confirm.require({
                group: 'templating',
                header: 'Atenção',
                message: `O perfil selecionado está vinculado a outro inventário de risco.`,
                icon: 'pi pi-exclamation-triangle',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                acceptLabel: 'Transferir',
                rejectLabel: 'Cancelar',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    if (alterar) {
                        await this.$emit('onAlterarPerfilExposicao', inventarioRiscoId, this.form.perfilExposicao.id, true);
                        this.onClickClose();
                        return;
                    }
                    await this.$emit('onIniciarAplicacao', inventarioRiscoId, this.form.perfilExposicao.id, true);
                    this.onClickClose();
                },
                reject: () => {
                    this.form.perfilExposicao = null;
                }
            });
        },
    }
}
</script>

<style>

</style>