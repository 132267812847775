import BaseService from "../../../services/BaseService";
import { getClientBase } from '../../../services/http'

export default class PerfilExposicaoService extends BaseService {
    constructor() {
        super('perfil-exposicao');
        this.path = 'perfil-exposicao';
    }

    verificarVinculacaoComInventarioRisco(id) {
        return getClientBase().get(`${this.path}/verificar-vinculacao-inventario-risco/${id}`);
    }
}
