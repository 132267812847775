import BaseService from "../../../services/BaseService";
import { getClientBase } from "../../../services/http";

export default class EtapasService extends BaseService {
    iniciar(payload, pathController) {
        return getClientBase().post(`${pathController}/iniciar`, {
            ...payload
        });
    }

    concluirEtapa(id, dataConclusao, pathController) {
        return getClientBase().patch(`${pathController}/concluir-etapa/${id}`, {
            dataConclusao
        });
    }
}
