<template>
    <AppOnePageDropdown
        ref="dropdown"
        v-model="value"
        :idValue="idValue"
        optionLabel="nome"
        placeholder="Selecione o perfil de exposição..."
        :service="$service"
        :itemSize="itemSize"
        :filtrosExtras="{ where }"
    >
    </AppOnePageDropdown>
</template>

<script>
import BaseService from '../../../services/BaseService';

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        customerId: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    watch: {
        customerId() {
            if (!this.customerId) return;
            setTimeout(async () => {
                await this.load();
            }, 100);
        }
    },
    data() {
        return {
            $service: null,
            where: [
                {
                    prop: 'customers.id',
                    operator: 'equal',
                    values: [this.customerId]
                }
            ]
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/perfil-exposicao');
    },
    methods: {
        async load() {
            this.$refs.dropdown.load({
                itemSize: this.itemSize,
                filtrosExtras: { customerId: this.customerId }
            });
        }
    }
};
</script>