<template>
    <div class="card">
        <slot name="header"></slot>
        <slot name="content"></slot>
        <slot name="acoesAplicacao"></slot>
        <slot name="steps"></slot>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>